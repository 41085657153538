<template>
  <div class="comptabilite-facture-frais">
    <div class="entete">
      <div class="box-all-filter">
        <div class="error-message ml-2 mr-2">
          <div v-if="errorFactures" class="error">
            <ul v-if="Array.isArray(errorFactures)" class="mb-0">
              <li v-for="(e, index) in errorFactures" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ errorFactures }}</div>
          </div>
        </div>
        <filter-component
          v-if="checkPermission('COTHFDD')"
          label="Date début"
          classLabel="label-box-style w-63-px"
        >
          <template v-slot:body>
            <date-picker
              v-model="date_debut"
              value-type="format"
              :disabled-date="disabledStartDate"
              @change="handleFilter(true)"
              type="date"
              required
              input-class="custom-date-picker-filter bg-white-color"
              class="custom-date-picker-calender-filter mt-2 mb-2"
            ></date-picker
          ></template>
        </filter-component>
        <filter-component
          label="Date fin"
          classLabel="label-box-style w-63-px"
          v-if="checkPermission('COTHFDF')"
        >
          <template v-slot:body>
            <date-picker
              v-model="date_fin"
              :disabled-date="disabledEndDate"
              @change="handleFilter(true)"
              value-type="format"
              type="date"
              required
              input-class="custom-date-picker-filter bg-white-color"
              class="custom-date-picker-calender-filter mt-2 mb-2"
            ></date-picker
          ></template>
        </filter-component>
        <!-- <span class="d-flex mr-2"
          ><font-awesome-icon :id="'tooltip-target-date_info'" icon="info" />
        </span>

        <b-tooltip :target="'tooltip-target-date_info'" triggers="hover">
          Intervalle maximum entre date début et date fin est une année
        </b-tooltip> -->
        <div
          v-if="loadingFactureComptabilite"
          class="chargement chargement-loading-icon m-0"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <b-button
            size="sm"
            class="button-export-style ml-2 mr-2"
            title="Filter"
            id="popover-target-1"
          >
            <font-awesome-icon icon="filter" />
          </b-button>
          <b-popover
            triggers="focus"
            target="popover-target-1"
            placement="top"
            custom-class="my-custom-class-popover-filter"
          >
            <filter-component
              label="Société"
              classLabel="w-100-px"
              v-if="checkPermission('COTHFV')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="vendeur"
                  :multiple="true"
                  @input="handleFilter"
                  label="text"
                  :options="computedGetVendeurs"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length
                      }}{{
                        values.length == 1
                          ? ' Société'
                          : values.length > 1
                          ? ' Sociétés'
                          : ''
                      }}</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              label="Client"
              classLabel="w-100-px
"
              v-if="checkPermission('COTHFA')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="acheteur"
                  @input="handleFilter"
                  :multiple="true"
                  :options="computedGetAcheteurs"
                  label="text"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length
                      }}{{
                        values.length == 1
                          ? ' Client'
                          : values.length > 1
                          ? ' Clients'
                          : ''
                      }}</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              label="Famille"
              classLabel="w-100-px"
              v-if="checkPermission('COTHFF')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="famille"
                  @input="handleFilter"
                  :multiple="true"
                  :options="computedListFamille"
                  label="text"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Famille</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Familles</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              label="Catégorie"
              classLabel="w-100-px
"
              v-if="checkPermission('COTHFC')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="categorie"
                  :multiple="true"
                  @input="handleFilter"
                  :options="computedGetCategories"
                  label="text"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Catégorie</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Catégories</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              label="Statut"
              classLabel="w-100-px
"
              v-if="checkPermission('COTHFS')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="statut"
                  :options="ListStatut"
                  @input="handleFilter"
                  :multiple="true"
                  label="text"
                  track-by="value"
                  class="customSelectMultipleFilter libres mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :preselect-first="false"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Statut</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Statuts</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              label="Type"
              classLabel="w-100-px
"
              v-if="checkPermission('COTHFT')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="type"
                  :options="ListType"
                  @input="handleFilter"
                  :multiple="true"
                  label="text"
                  track-by="value"
                  class="customSelectMultipleFilter libres mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :preselect-first="false"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Type</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Types</span
                    >
                  </template>
                </multiselect>
              </template>
            </filter-component>
            <filter-component
              classLabel="w-100-px"
              v-if="checkPermission('COTHFCO')"
              label="Comptabilisé"
            >
              <template v-slot:body>
                <b-form-select
                  class="
                    b-form-select-new-style b-form-select-raduis-border
                    mt-2
                    mb-2
                  "
                  v-model="comptabilite"
                  :options="computedComptabilisé"
                  text-field="text"
                  value-field="value"
                  @change="handleFilter"
                ></b-form-select
              ></template>
            </filter-component>
            <filter-component
              label="Numéro de lot"
              classLabel="w-100-px"
              v-if="checkPermission('COTHFL')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="mail_lot"
                  :multiple="true"
                  @input="handleFilter"
                  :options="computedLot"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }}
                    </span>
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} lots</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
          </b-popover>
          <comptabilise
            v-if="
              computedCheckRowsSelected.length > 0 &&
                checkPermission('COTHMLCC')
            "
            @end="handleResetCheck"
            :methods="changeCompta"
            :selected="computedCheckRowsSelected"
          ></comptabilise>
          <export-dynamic
            v-if="checkPermission('COTHTZIP') || checkPermission('COTHTXLS')"
            :filter="computedFilter"
            :selected="computedCheckRows"
            :exportXls="exportFactureLibre"
            :famille="fam"
            :exportZip="downloadZIP"
            @end="handleResetCheck"
            :xlsRequired="checkPermission('COTHTXLS')"
            :zipRequired="checkPermission('COTHTZIP')"
          ></export-dynamic>
          <search
            v-if="checkPermission('COTHR')"
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
            :dynamic="false"
            placeHolder="Num dossier"
          ></search>
        </div>
      </div>
    </div>
    <!-- block filtre -->
    <div>
      <div
        v-if="
          date_debut !== null ||
            date_fin !== null ||
            searchValue !== null ||
            comptabilite !== null ||
            getShowNotifExportFile == true
        "
        class="block-filter-style"
      >
        <span class="phrase-style">Les filtres choisie sont : </span>
        <span class="item-filter-style" v-if="date_debut !== null"
          >Date début : {{ date_debut }}</span
        >
        <span class="item-filter-style" v-if="date_fin !== null"
          >Date fin : {{ date_fin }}</span
        >
        <span v-if="searchValue !== null" class="item-filter-style"
          >Recherche : {{ searchValue }}</span
        >
        <span v-if="comptabilite !== null" class="item-filter-style"
          >Comptabilisé : {{ comptabilite }}</span
        >
        <div class="notif-export-file" v-if="getShowNotifExportFile">
          Préparation du fichier en cours
        </div>
      </div>
    </div>
    <!--End block filtre -->
    <div class="chip-filters-simulation">
      <div v-if="vendeur && vendeur.length" class="block-filter">
        <span class="title-block-chip">Société : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="vendeur"
          @input="handleFilter"
        >
          <vs-chip
            :key="vd.text + 'master-filiale'"
            @click="remove(vd, 'vendeur', 'handleFilter')"
            v-for="vd in vendeur"
            closable
          >
            {{ vd.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="acheteur && acheteur.length" class="block-filter">
        <span class="title-block-chip">Client : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="acheteur"
          @input="handleFilter"
        >
          <vs-chip
            :key="acht.text + 'acheteur'"
            @click="remove(acht, 'acheteur', 'handleFilter')"
            v-for="acht in acheteur"
            closable
          >
            {{ acht.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="famille && famille.length" class="block-filter">
        <span class="title-block-chip">Famille : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="famille"
          @input="handleFilter"
        >
          <vs-chip
            :key="fam.text + 'famille'"
            @click="remove(fam, 'famille', 'handleFilter')"
            v-for="fam in famille"
            closable
          >
            {{ fam.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="categorie && categorie.length" class="block-filter">
        <span class="title-block-chip">Catégorie : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="categorie"
          @input="handleFilter"
        >
          <vs-chip
            :key="cat.text + 'cat'"
            @click="remove(cat, 'categorie', 'handleFilter')"
            v-for="cat in categorie"
            closable
          >
            {{ cat.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="statut && statut.length" class="block-filter">
        <span class="title-block-chip">Statut : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="statut"
          @input="handleFilter"
        >
          <vs-chip
            :key="st.text + 'cat'"
            @click="remove(st, 'statut', 'handleFilter')"
            v-for="st in statut"
            closable
          >
            {{ st.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="type && type.length" class="block-filter">
        <span class="title-block-chip">Type : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="type"
          @input="handleFilter"
        >
          <vs-chip
            :key="tp.text + 'type'"
            @click="remove(tp, 'type', 'handleFilter')"
            v-for="tp in type"
            closable
          >
            {{ tp.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="mail_lot && mail_lot.length" class="block-filter">
        <span class="title-block-chip">Lot : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="mail_lot"
          @input="handleFilter"
        >
          <vs-chip
            :key="tp + 'mail_lot'"
            @click="remove(tp, 'mail_lot', 'handleFilter')"
            v-for="tp in mail_lot"
            closable
          >
            {{ tp }}
          </vs-chip>
        </vs-chips>
      </div>
    </div>
    <div class="body-box-rapport" :style="sizeBlockTable">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="
            custom-table-style
            customTableComptabiliteFactureFrais
            table-header
          "
          bordered
          :style="sizeTable"
          :items="factures"
          :fields="computedFields"
          sticky-header
          hover
          responsive
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:head()="data">
            <div v-if="data.field.key === 'check_all'">
              <b-form-checkbox
                @change="checkAllFunction"
                class="check-th"
                :value="true"
                :unchecked-value="false"
                v-model="checkAll"
              >
              </b-form-checkbox>
            </div>
            <div
              v-else
              :class="{
                'd-flex justify-content-center align-items-center':
                  data.field.isSortable === true,
                'd-flex justify-content-center':
                  data.field.isSortable === false,
                'underline-selected-colomn': data.field.key === column
              }"
            >
              {{ data.field.label }}

              <font-awesome-icon
                v-if="data.field.isSortable === true"
                @click="listOrder(data.field.key, 'ASC')"
                icon="arrow-up"
                :class="
                  data.field.key === column && order === 'ASC'
                    ? 'style-arrow-selected'
                    : 'style-arrow-not-selected'
                "
              />
              {{ ' ' }}
              <font-awesome-icon
                @click="listOrder(data.field.key, 'DESC')"
                v-if="data.field.isSortable === true"
                icon="arrow-down"
                :class="
                  data.field.key === column && order === 'DESC'
                    ? 'style-arrow-selected'
                    : 'style-arrow-not-selected'
                "
              />
            </div>
          </template>
          <template v-slot:cell(check_all)="data"
            >{{ data.item.check_all }}
            <b-form-checkbox
              v-model="data.item.check"
              @change="checkRows(data.item)"
              name="flavour-1"
              class="check-th"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(date_creation)="data">
            {{ data.item.date_creation | formateDate }}
          </template>
          <template v-slot:cell(details)="data">
            <button
              class="btn btn-anomalie"
              v-if="data.item.factureAvoir.length > 0"
              @click="data.toggleDetails"
            >
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
                title="Afficher les avoirs"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
                title="Masquer les avoirs"
              />
            </button>
          </template>
          <template #row-details="data">
            <span v-for="item in data.item.factureAvoir" :key="item.id">
              <td class="col-check-obligee">
                <div>
                  <b-form-checkbox
                    v-model="item.checkAvoir"
                    class="check-th"
                    :value="true"
                    :unchecked-value="false"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td class="col-plus-obligee">
                <div
                  class="block-service-style d-flex justify-content-center"
                ></div>
              </td>
              <td class="item-details-style">
                <div>
                  {{ item.vendeur }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.type }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.famille }}
                </div>
              </td>
              <td class="item-details-style">
                <div id="info-wrap">
                  <div class="description-column">
                    {{ item.num }}
                  </div>
                  <div class="info-column" v-if="checkPermission('COTHTTFA')">
                    <font-awesome-icon
                      icon="arrow-alt-circle-down"
                      @click.prevent="downloadFacture(item.id)"
                      class="icon-style-color mr-1 cursor-pointer"
                      title="Télécharger la facture"
                    />
                  </div>
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.date_creation | formateDate }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.acheteur }}
                </div>
              </td>

              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.etat }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.date_paiement | formateDate }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{
                    item.categorie && item.categorie.nom_categorie
                      ? item.categorie.nom_categorie
                      : '-'
                  }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.total_net_ht }} {{ item.devise }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.total_tva }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.total_ttc }} {{ item.devise }}
                </div>
              </td>
              <td class="item-details-style">
                <div
                  class="block-service-style d-flex justify-content-center m-1"
                >
                  {{ data.item.comptabilise }}
                </div>
              </td>
              <td class="item-details-style">
                <div
                  class="block-service-style d-flex justify-content-center m-1"
                >
                  {{ data.item.mail_lot }}
                </div>
              </td>
              <td class="item-details-style" v-if="checkPermission('COTHACH')">
                <b-button
                  size="sm"
                  title="Historique Facture"
                  class="btn-historique"
                  v-if="checkPermission('COTHACH')"
                >
                  <font-awesome-icon icon="file-invoice" />
                </b-button>
              </td>
            </span>
          </template>
          <template v-slot:cell(num)="data">
            <div id="info-wrap" class="d-flex">
              <div class="description-column mr-1">
                {{ data.item.num }}
              </div>
              <div class="info-column" v-if="checkPermission('COTHTFACT')">
                <font-awesome-icon
                  icon="arrow-alt-circle-down"
                  @click.prevent="downloadFacture(data.item.id)"
                  class="icon-style-color mr-1 cursor-pointer"
                  title="Télécharger la facture"
                />
              </div>
              <!-- <div
                class="info-column mr-1 duplicate-button-stayle"
                v-if="checkPermission('COTHTDF')"
              >
                <font-awesome-icon
                  icon="clone"
                  @click.prevent="duplicateFactureCompta(data.item.id)"
                  class="
                    icon-style-color
                    mr-1
                    cursor-pointer
                    clone-icon-stayle-dup
                  "
                  title="Dupliquer la facture"
                />
              </div> -->
            </div>
          </template>
          <template v-slot:cell(comptabilise)="data">
            {{ data.item.comptabilise == null ? '--' : data.item.comptabilise }}
          </template>
          <template v-slot:cell(historique)="data">
            <Historique
              :awesome="true"
              :index="data.item.id"
              :permission="checkPermission('COTHACH')"
              :dataToUse="data.item.historique"
            />
          </template>
        </b-table>
      </div>
    </div>
    <div class="footer-style mt-2">
      <b-pagination
        v-model="page"
        :per-page="perPage"
        :total-rows="getRows"
        aria-controls="my-table"
        pills
        align="center"
        size="sm"
        @change="pagination"
        class="pagination-style"
      ></b-pagination>

      <div class="per-page-element-style">
        <div class="box-label-champ">
          <div class="label-box-style">
            <span class="title-tabel">Eléments par page</span>
          </div>
        </div>
        <b-form-select
          v-model="perPage"
          :options="perPageList"
          @change="handleFilter"
          class="b-form-select-new-style bg-select"
        ></b-form-select>
      </div>
      <div
        class="style-somme-th-ttc"
        v-if="computedTotalHt || computedTotalTtc"
      >
        <p class="m-2">
          Total NET HT:
          {{ computedTotalHt.toFixed(2) }}€ {{ '   ' }}Total TTC:

          {{ computedTotalTtc.toFixed(2) }} €
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import filterComponent from '../frais/component/filterComponent.vue';
import ExportDynamic from '../frais/fraisDynamic/component/exportDynamic.vue';
import Comptabilise from './component/comptabilise.vue';
import moment from 'moment';
export default {
  data() {
    return {
      ListStatut: [
        { value: 'Créé', text: 'Créé' },
        { value: 'Payé', text: 'Payé' },
        { value: 'A payer', text: 'A payer' },
        { value: 'Payé en partie', text: 'Payé en partie' },
        { value: 'Annulée', text: 'Annulée' }
      ],
      ListType: [
        { value: 'Facture', text: 'Facture' },
        // { value: 'Facture proforma', text: 'Facture proforma' },
        { value: "Facture d'avoir", text: "Facture d'avoir" }
      ],
      perPageList: [
        { value: 100, text: 100 },
        { value: 200, text: 200 },
        { value: 300, text: 300 }
      ],
      acheteur: [],
      famille: [],
      statut: [],
      type: [],
      vendeur: [],
      categorie: [],
      date_debut: null,
      date_fin: null,
      isOpen: false,
      page: 1,
      perPage: 100,
      column: 'id',
      order: 'DESC',
      searchValue: null,
      fam: 'all',
      checkAll: false,
      comptabilite: null,
      mail_lot: []
    };
  },
  computed: {
    ...mapGetters([
      'getFiliaeOfResponsable',
      'getAllCategories',
      'getSettingFilialesTh',
      'loadingFactureComptabilite',
      'getRows',
      'errorFactures',
      'factures',
      'checkPermission',
      'familles',
      'checkPermission',
      'typesCompta',
      'getShowNotifExportFile',
      'getLotsCompta'
    ]),
    computedLot() {
      return this.getLotsCompta;
    },
    computedComptabilisé() {
      let types = [];
      types.push({
        value: null,
        text: 'Tous'
      });
      for (let i = 0; i < this.typesCompta.length; i++) {
        types.push({
          value: this.typesCompta[i],
          text: this.typesCompta[i]
        });
      }

      return types;
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 245 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeBlockTable() {
      let heigthBlock = 0;

      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 199 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },

    computedCheckChipFilter() {
      if (
        this.vendeur.length > 0 ||
        this.acheteur.length > 0 ||
        this.famille.length > 0 ||
        this.categorie.length > 0 ||
        this.statut.length > 0 ||
        this.type.length > 0 ||
        this.mail_lot.length > 0
      ) {
        return true;
      }
      return false;
    },
    computedListFamille() {
      let familles = [];
      for (let i = 0; i < this.familles.length; i++) {
        familles.push({
          value: this.familles[i],
          text: this.familles[i]
        });
      }
      return familles;
    },
    computedFields() {
      let fields = [
        {
          key: 'check_all',
          label: '',
          thClass: 'th-check-obligee',
          tdClass: 'col-check-obligee',
          show: true
        },
        {
          key: 'details',
          label: '',
          thClass: 'th-plus-obligee',
          tdClass: 'col-plus-obligee',
          show: true
        },
        {
          key: 'Vendeur',
          label: 'SOCIÉTÉ',
          isSortable: true,
          show: this.checkPermission('COTHACV')
        },
        {
          key: 'type',
          label: 'TYPE',
          isSortable: true,
          show: this.checkPermission('COTHACT')
        },
        {
          key: 'famille',
          label: 'FAMILLE',
          isSortable: true,
          show: this.checkPermission('COTHACF')
        },
        {
          key: 'num',
          label: 'NUMERO',
          isSortable: true,
          show: this.checkPermission('COTHACN')
        },

        {
          key: 'date_creation',
          label: 'DATE DE CRÉATION',
          isSortable: true,
          show: this.checkPermission('COTHACDDC')
        },
        {
          key: 'Acheteur',
          label: 'CLIENT',
          isSortable: true,
          show: this.checkPermission('COTHACA')
        },
        {
          key: 'etat',
          label: 'ETAT',
          isSortable: true,
          show: this.checkPermission('COTHACE')
        },
        {
          key: 'date_paiement',
          label: 'DATE DE PAIEMENT',
          isSortable: true,
          show: this.checkPermission('COTHACDDP')
        },
        {
          key: 'categorie',
          label: 'CATEGORIE',
          isSortable: true,
          show: this.checkPermission('COTHAC')
        },
        {
          key: 'total_net_ht',
          label: 'NET HT',
          isSortable: true,
          show: this.checkPermission('COTHACNETHT')
        },
        {
          key: 'total_tva',
          label: 'TVA',
          isSortable: true,
          show: this.checkPermission('COTHACTVA')
        },
        {
          key: 'total_ttc',
          label: 'TOTAL TTC',
          isSortable: true,
          show: this.checkPermission('COTHACTTC')
        },
        {
          key: 'comptabilise',
          label: 'Comptabilisé',
          isSortable: true,
          show: this.checkPermission('COTHACCOM')
        },
        {
          key: 'mail_lot',
          label: 'Numéro de lot',
          isSortable: true,
          show: this.checkPermission('COTHACNL')
        },
        {
          key: 'historique',
          label: 'Historique',
          isSortable: true,
          show: this.checkPermission('COTHACH')
        }
      ];

      return fields.filter(field => field.show == true);
    },
    computedCheckRows() {
      let selected = [];
      this?.factures?.map(facture => {
        if (facture.check == true) {
          selected.push(facture.id);
        }
      });
      return selected;
    },
    computedTotalHt() {
      let sum = 0;
      let sumAvoir = 0;
      this.computedCheckRowsSelected.map(item => {
        sum += item.net_ht;
        for (let i = 0; i < item.factureAvoir.length; i++) {
          sumAvoir += item.factureAvoir[i].total_ht;
        }
      });
      return Math.round(sum * 100) / 100 - Math.round(sumAvoir * 100) / 100;
    },
    computedTotalTtc() {
      let sum = 0;
      let sumAvoir = 0;
      this.computedCheckRowsSelected.map(facture => {
        sum += facture.ttc;
        for (let i = 0; i < facture.factureAvoir.length; i++) {
          sumAvoir += parseFloat(
            facture.factureAvoir[i].total_ttc.replace(' ', '')
          );
        }
      });
      return Math.round(sum * 100) / 100 - Math.round(sumAvoir * 100) / 100;
    },
    computedCheckRowsSelected() {
      let selected = [];
      this?.factures?.map(facture => {
        if (facture.check == true) {
          selected.push(facture);
        }
      });
      return selected;
    },
    computedFilter() {
      return {
        vendeur: this.vendeur,
        acheteur: this.acheteur,
        categorie: this.categorie,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        type: this.type,
        etat: this.statut,
        col: this.column,
        num: this.searchValue,
        family: this.famille,
        comptabilite: this.comptabilite
      };
    },
    computedGetAcheteurs() {
      let acheteurNames = this.getSettingFilialesTh.map(item => {
        return { value: item.id, text: item.name };
      });
      return acheteurNames;
    },
    computedGetVendeurs() {
      let vendeurNames = this.getFiliaeOfResponsable.map(item => {
        return { value: item.id, text: item.name };
      });
      return vendeurNames;
    },
    computedGetCategories() {
      let categoriesNames = this.getAllCategories.map(item => {
        return { value: item.id, text: item.nom_categorie };
      });
      return categoriesNames;
    }
  },
  methods: {
    ...mapActions([
      'changeCompta',
      'getSettingFilialeTh',
      'getCategoriesFactureLibre',
      'getFilialsOfConnectedResponsable',
      'getFactures',
      'exportFactureLibre',
      'downloadZIP',
      'downloadPDF',
      'getFamilles',
      'getTypeOfCompta',
      'getLotCompta'
    ]),
    handleFetchLot() {
      this.getLotCompta({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
    },
    duplicateFactureCompta(id) {
      this.$router.push(`/duplicate-facture/${id}`);
    },
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      this[fct]();
    },
    downloadFacture(id) {
      this.downloadPDF({ id: id });
    },
    handleResetCheck(value) {
      if (value == false) {
        this.resetCheck();
      }
    },
    resetCheck() {
      this.checkAll = false;
      this?.factures?.map(facture => (facture.check = false));
    },
    checkRows(item) {
      let checked = [];
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      this?.factures?.map(facture => {
        if (facture.check == true) {
          checked.push(facture);
        }
      });
      if (checked.length == this.factures.length) {
        this.checkAll = true;
      }
    },
    checkAllFunction() {
      this.factures.map(facture => (facture.check = this.checkAll));
    },
    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    changeSearchValue(event) {
      this.searchValue = event;
      this.handleFilter();
    },
    async handleFilter(fetchFilter) {
      if (fetchFilter) {
        this.handleFetchLot();
      }
      this.resetCheck();
      this.setLocalStorageComptabilite();
      await this.getFactures({
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        etat: this.statut,
        type: this.type,
        family: this.famille,
        vendeur_id: this.vendeur,
        acheteur_id: this.acheteur,
        categorie_id: this.categorie,
        per_page: this.perPage,
        page: this.page,
        order: this.order,
        column: this.column,
        famille: 'all',
        num: this.searchValue,
        comptabilite: this.comptabilite,
        mail_lot: this.mail_lot,
        no_proforma: true
      });
    },
    async pagination(paginate) {
      this.page = paginate;
      await this.getFactures({
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        etat: this.statut,
        type: this.type,
        family: this.famille,
        vendeur_id: this.vendeur,
        acheteur_id: this.acheteur,
        categorie_id: this.categorie,
        per_page: this.perPage,
        page: this.page,
        order: this.order,
        column: this.column,
        famille: 'all',
        num: this.searchValue,
        comptabilite: this.comptabilite,
        no_proforma: true
      });
    },
    async listOrder(col, ord) {
      this.column = col;
      this.order = ord;
      await this.getFactures({
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        etat: this.statut,
        type: this.type,
        family: this.famille,
        vendeur_id: this.vendeur,
        acheteur_id: this.acheteur,
        categorie_id: this.categorie,
        per_page: this.perPage,
        page: this.page,
        order: this.order,
        column: this.column,
        famille: 'all',
        num: this.searchValue,
        comptabilite: this.comptabilite,
        no_proforma: true
      });
    },
    setLocalStorageComptabilite() {
      localStorage.setItem(
        'comptabilite-cdg-th',
        JSON.stringify({
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          etat: this.statut,
          typeFacture: this.type,
          family: this.famille,
          vendeur_id: this.vendeur,
          acheteur_id: this.acheteur,
          categorie_id: this.categorie,
          per_page: this.perPage,
          page: this.page,
          order: this.order,
          column: this.column,
          famille: this.fam,
          num: this.searchValue,
          comptabilite: this.comptabilite
        })
      );
    }
  },
  filters: {
    formateDate: value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY');
      }
    }
  },
  components: {
    filterComponent,
    search: () => import('@/views/component/SearchInputComponent.vue'),
    ExportDynamic,
    Comptabilise,
    Historique: () => import('@/views/component/Historique.vue')
  },
  async mounted() {
    await this.getFamilles();
    await this.getTypeOfCompta();
    if (localStorage.getItem('comptabilite-cdg-th')) {
      this.date_debut = JSON.parse(
        localStorage.getItem('comptabilite-cdg-th')
      ).date_debut;
      this.date_fin = JSON.parse(
        localStorage.getItem('comptabilite-cdg-th')
      ).date_fin;
      this.vendeur = JSON.parse(
        localStorage.getItem('comptabilite-cdg-th')
      ).vendeur_id;
      this.acheteur = JSON.parse(
        localStorage.getItem('comptabilite-cdg-th')
      ).acheteur_id;
      this.categorie = JSON.parse(
        localStorage.getItem('comptabilite-cdg-th')
      ).categorie_id;
      this.famille = JSON.parse(
        localStorage.getItem('comptabilite-cdg-th')
      ).family;
      this.type = JSON.parse(localStorage.getItem('comptabilite-cdg-th'))
        .typeFacture
        ? JSON.parse(localStorage.getItem('comptabilite-cdg-th')).typeFacture
        : [];
      this.statut = JSON.parse(localStorage.getItem('comptabilite-cdg-th')).etat
        ? JSON.parse(localStorage.getItem('comptabilite-cdg-th')).etat
        : [];
      this.searchValue = JSON.parse(
        localStorage.getItem('comptabilite-cdg-th')
      ).num;
      this.comptabilite = JSON.parse(
        localStorage.getItem('comptabilite-cdg-th')
      ).comptabilite;
      this.order = JSON.parse(
        localStorage.getItem('comptabilite-cdg-th')
      ).order;
      this.column = JSON.parse(
        localStorage.getItem('comptabilite-cdg-th')
      ).column;
      this.fam = JSON.parse(
        localStorage.getItem('comptabilite-cdg-th')
      ).famille;
    } else {
      this.date_fin = moment(new Date()).format('YYYY-MM-DD');
      this.date_debut = moment(this.date_fin)
        .subtract(12, 'months')
        .format('YYYY-MM-DD');

      this.setLocalStorageComptabilite();
    }
    this.handleFilter(true);
    await this.getCategoriesFactureLibre();
    await this.getFilialsOfConnectedResponsable();
    await this.getSettingFilialeTh();
  }
};
</script>

<style scoped lang="scss">
.duplicate-button-stayle {
  background-color: green;
  border-radius: 10px;
}
.clone-icon-stayle-dup {
  margin: 3px;
  color: #f2f2f2;
  font-size: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.underline-selected-colomn {
  text-decoration: underline;
}
.style-arrow-not-selected {
  width: 14px;
  height: 14px;
  color: #aaacb0;
  // margin-top: 3px;
  margin-left: 1px;
  margin-right: 1px;
  cursor: pointer;
}
.style-arrow-selected {
  width: 14px;
  height: 14px;
  color: #4d4bac;
  margin-left: 1px;
  margin-right: 1px;
  cursor: pointer;
}
.comptabilite-facture-frais {
  padding-top: 20px;
  padding-left: 15px;
  width: 99%;
  .entete {
    padding: 5px 10px;
  }
  .body-box-rapport {
    height: calc(100vh - 188px);
    .customTableComptabiliteFactureFrais {
      height: 100%;
      max-height: 100%;
      margin-bottom: 0px;
    }
  }
  .show-coef-button {
    background-color: green;
    font-size: 14px;
    padding: 3px 12px 0px 12px;
  }
  .show-montant-button {
    background-color: red;
    font-size: 14px;
    padding: 3px 12px 0px 12px;
  }
  .style-somme-th-ttc {
    font-family: 'Montserrat', sans-serif;
    background-color: #d0e4f5;
    position: absolute;
    left: 1px;
    // bottom: 0px;
    width: auto;
    height: 35px;
    border-radius: 30px;
    z-index: 120;
    margin-left: 33px;
  }
}
</style>
<style lang="scss">
.color {
  border-left: none !important;
}
.color {
  border-left: none !important;
}
.table-rapport-style .customTableComptabiliteFactureFrais td,
.customTableComptabiliteFactureFrais th {
  width: calc(100vh / 4);
  line-height: 10px;
  span {
    cursor: pointer;
  }
}
.table-rapport-style .size-table-custom {
  width: 100%;
}
.table-rapport-style .customTableComptabiliteFactureFrais {
  .item-details-style {
    border: 1px solid #fff;
    //  width: 15px !important;
    display: table-cell;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #4d4bac !important;
    background-color: #4d4bac !important;
  }
  .multiselect__tag {
    background: #4d4bac;
  }
  .multiselect__tag-icon:after {
    color: #ffffff;
  }
  #checkbox-group-1 {
    text-transform: capitalize;
    margin: auto;
    display: table;
  }
  .colhead-num-dossier-th,
  .col-num-dossier-th {
    left: 30px !important;
  }
  .thvalidation,
  .thvalidation {
    width: 50px !important;
  }
  td,
  th {
    font-size: 9px;
    // width: 107px;
    // min-width: 107px;
  }
  .btn-anomalie {
    padding: 0px;
  }
  tr.b-table-details > td {
    padding: 0px;
  }
  .background-week {
    justify-content: start !important;
    vertical-align: middle;
    td {
      padding: 8px 5px;
      margin: 0px;
      font-size: 9.5px;
    }

    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
  }
}
.blok-service-style {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.visibility-stiky-row-ssetails {
  background: #ededed;
  border: none !important;
}
.tr-vide {
  line-height: 25px;
}

#info-wrap {
  overflow: hidden;
}

.description-column {
  width: 90%;
  float: left;
}
.info-column {
  width: 10%;
  float: left;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
